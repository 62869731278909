import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { EmailVerification } from "./EmailVerification/EmailVerification";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
import { useTranslation } from "react-i18next";
import Header from "../Component/Common/Header";
import Dashboard from "./Dashboard/Dashboard";
import SpotTrade from "./Trade/SpotTrade";
import WalletPage from "./WalletPage/WalletPage";
import Verification from "../Component/Auth/Verification";
import ErrorPage from "../Component/Auth/ErrorPage";
import ReferralPage from "./ReferralPage";
import WithdrawPage from "./WithdrawPage/WithdrawPage";
import DepositPage from "./DepositPage/DepositPage";
import TransferPage from "./TransferPage";
import Signin from "./Signin";
import Signup from "./Signup";
import Account from "./Account";
import ResetPassword from "./ResetPassword";
import SSOAuth from "./SSOAuth.js";
import APIManagement from "./APIManagement";
import PNL from "./PNL";
import LuckyWheel from "./LuckyWheel";

const DISABLE_HEADER = ["/sign-in", "/sign-up", "/auth"];
const ENABLE_MOBILE = [
	"/",
	"/sign-in",
	"/sign-up",
	"/password-reset",
	"/spot-trade",
	"/wallet-page",
	"/deposit-page",
	"/transfer-page",
	"/withdraw-page",
	"/pnl",
	"/account-page",
	"/referral",
	"/email-confirmation",
];

function MainIndex(props) {
	const baseUrl = process.env.PUBLIC_URL;
	const location = useLocation();

	const [showHeader, setShowHeader] = useState(false);
	const [showBadge, setShowBadge] = useState(false);
	const [isShow, setIsShow] = useState(false);

	const [width] = useWindowSize();
	const [t] = useTranslation("common");

	useEffect(() => {
		if (DISABLE_HEADER.includes(location.pathname)) {
			setShowHeader(false);
		} else {
			setShowHeader(true);
		}

		if (location.pathname === "/") {
			setShowBadge(true);
		} else {
			setShowBadge(false);
		}
	}, [location]);

	useState(() => {
		if (width < 1280 && !ENABLE_MOBILE.includes(location.pathname)) {
			setIsShow(false);
		} else {
			setIsShow(true);
		}
	}, [width, location]);

	return (
		<div className="main">
			{!isShow ? (
				<div className="not-optimized">
					<h1>{t("sorry")}</h1>
					<h5>{t("notOptimized")}</h5>
				</div>
			) : (
				<>
					{showHeader ? <Header /> : null}
					<div
						className={`${
							showHeader ? "pad-top92 body d-flex" : ""
						}`}
					>
						<ToastContainer />
						<Routes>
							<Route
								exact
								path={baseUrl + "/"}
								element={<Dashboard />}
							/>
							<Route
								exact
								path={baseUrl + "/spot-trade"}
								element={<SpotTrade />}
							/>
							<Route
								exact
								path={baseUrl + "/account-page"}
								element={<Account />}
							/>
							<Route
								exact
								path={baseUrl + "/wallet-page"}
								element={<WalletPage />}
							/>
							<Route
								exact
								path={baseUrl + "/withdraw-page"}
								element={<WithdrawPage />}
							/>
							<Route
								exact
								path={baseUrl + "/deposit-page"}
								element={<DepositPage />}
							/>
							<Route
								exact
								path={baseUrl + "/transfer-page"}
								element={<TransferPage />}
							/>
							<Route
								exact
								path={baseUrl + "/referral"}
								element={<ReferralPage />}
							/>
							<Route
								exact
								path={baseUrl + "/sign-in"}
								element={<Signin />}
							/>
							<Route
								exact
								path={baseUrl + "/sign-up"}
								element={<Signup />}
							/>
							<Route
								exact
								path={baseUrl + "/password-reset"}
								element={<ResetPassword />}
							/>
							<Route
								exact
								path={baseUrl + "/verification"}
								element={<Verification />}
							/>
							<Route
								path={baseUrl + "/email-confirmation"}
								element={<EmailVerification />}
							/>
							<Route
								path={baseUrl + "/auth"}
								element={<SSOAuth />}
							/>
							<Route
								path={baseUrl + "/api-management"}
								element={<APIManagement />}
							/>
							<Route
								path={baseUrl + "/lucky-wheel"}
								element={<LuckyWheel />}
							/>
							<Route path={baseUrl + "/pnl"} element={<PNL />} />
							<Route
								exact
								path={baseUrl + "/404page"}
								element={<ErrorPage />}
							/>
						</Routes>
					</div>
				</>
			)}
			{width > 1200 && showBadge ? (
				<div className="stickyBadge">
					<a
						href="https://www.producthunt.com/posts/frypto?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-frypto"
						target="_blank"
					>
						<img
							src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=378926&theme=neutral"
							alt="Frypto - Crypto&#0032;trading&#0032;made&#0032;simple | Product Hunt"
							width="250"
							height="54"
						/>
					</a>
				</div>
			) : null}
		</div>
	);
}
export default MainIndex;
