import React from "react";
import Modal from "react-bootstrap/Modal";
import ConfettiExplosion from "react-confetti-explosion";
import styles from "./styles.module.scss";

const RewardModal = ({ openModal, handleClose, reward }) => {
	const largeProps = {
		force: 0.8,
		duration: 3000,
		particleCount: 300,
		width: 1600,
		zIndex: 9999999999,
		// colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
	};
	return (
		<>
			<Modal show={openModal} onHide={handleClose}>
				<div className={styles.rewardModal}>
					<ConfettiExplosion {...largeProps} />
					<div>{reward}</div>
				</div>
			</Modal>
		</>
	);
};

export default RewardModal;
