import React, { useState, useRef } from "react";
import PageTitle from "../../Component/Common/PageTitle";
import OrderBook from "../../Component/Exchange/OrderBook";
import Spot from "../../Component/Exchange/Spot";
import SpotTradeStatus from "../../Component/Exchange/SpotTradeStatus";
// import TradingViewWidget from "react-ts-tradingview-widget";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { AdvancedChart } from "react-tradingview-embed";

import TradeComponent from "../../Component/Exchange/TradeComponent";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";

import ProPriceDetail from "../../Component/Exchange/ProPriceDetail";
import { useTranslation } from "react-i18next";
import Overlay from "react-bootstrap/Overlay";
import { useWindowSize } from "@react-hook/window-size";
import LitePriceDetail from "../../Component/Exchange/LitePriceDetail";
import MobileTradeComponent from "../../Component/Exchange/MobileTradeComponent";
import autoAnimate from "@formkit/auto-animate";

function Pro({ openSelectMarket }) {
	const [t] = useTranslation("trade");
	const auth = useSelector((state) => state.auth);
	const search = useLocation().search;
	const symbol = new URLSearchParams(search).get("token");
	let removeSquadSymbol = symbol.split("-").join("");
	let symbolUpercase = removeSquadSymbol.toLocaleUpperCase();
	const [symbolOrderBook, setOrderBook] = useState(symbolUpercase);
	const [symbolTradingView, setSymbolTradingView] = useState(
		"BINANCE:" + symbolUpercase
	);

	const [width, height] = useWindowSize();

	const [chartWidth, setChartWidth] = useState(830);

	const [allowSymbolChange, setAllowSymbolChange] = useState(false);

	const [getPriceFromOrder, setPriceFromOrder] = useState(0);
	const [clickBuy, setClickBuy] = useState(false);
	const [clickSell, setClickSell] = useState(false);

	const [startPrice, setStartPrice] = useState(0);

	const [refreshData, setRefreshData] = React.useState(false);

	const [lastPrice, setLastPrice] = useState(0);

	const target = useRef(null);

	const [showCaution, setShowCaution] = useState(false);

	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		if (width >= 1366) {
			setChartWidth(830);
		} else if (width >= 1200) {
			setChartWidth((1200 / 1366) * 830 * 0.8);
		} else if (width < 1200) {
			setChartWidth(1200 - 16 * 2); //padding
		}
	}, [width]);

	return (
		<>
			<>
				{width > 1200 ? (
					<div ref={parent}>
						<ProPriceDetail openSelectMarket={openSelectMarket} />
						<div className={styles.tradeContainerPro}>
							<OrderBook
								symbolOrderBook={symbolOrderBook}
								setPriceFromOrder={setPriceFromOrder}
								setClickBuy={setClickBuy}
								setClickSell={setClickSell}
								setStartPrice={setStartPrice}
								priceToken24h={{}}
								lastPrice={lastPrice}
							/>
							<div className={styles.tvContainer}>
								<div
									className={styles.tvCaution}
									ref={target}
									// onMouseEnter={() => setShowCaution(true)}
									onMouseLeave={() => setShowCaution(false)}
									onClick={() => setShowCaution(!showCaution)}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
											fill="#FDFCFF"
											stroke="currentColor"
										/>
										<rect
											x="11"
											y="6"
											width="2"
											height="8"
											rx="1"
											fill="currentColor"
										/>
										<circle
											cx="12"
											cy="17"
											r="1"
											fill="currentColor"
										/>
									</svg>
								</div>

								<div
									className="contentContainerMedium"
									style={{
										width: chartWidth,
									}}
								>
									<AdvancedChart
										widgetProps={{
											theme: "light",
											allow_symbol_change: false,
											symbol: symbolTradingView,
											interval: "D",
											locale:
												auth.lang === "vi"
													? "vi_VN"
													: "en_US",
											hide_side_toolbar: false,
											show_popup_button: true,
											popup_width: 1000,
											popup_height: 650,
											timezone:
												Intl.DateTimeFormat().resolvedOptions()
													.timeZone,
											style: "1",
											withdateranges: false,
											height: 500,
										}}
									/>
								</div>
							</div>

							<TradeComponent />
						</div>
						{showCaution ? (
							<Overlay
								target={target.current}
								show={true}
								placement="bottom"
							>
								{({
									placement,
									arrowProps,
									show: _show,
									popper,
									...props
								}) => (
									<div
										{...props}
										className="contentContainerMedium"
									>
										<div
											className={styles.tvCautionContent}
										>
											{t("tvCaution")}
										</div>
									</div>
								)}
							</Overlay>
						) : null}
					</div>
				) : (
					<div className={styles.tradeContainerPro} ref={parent}>
						<LitePriceDetail openSelectMarket={openSelectMarket} />
						<div
							className="contentContainerMedium"
							style={{
								width: "100%",
								height: 500,
								borderRadius: 0,
							}}
						>
							{/* <TradingViewWidget
								allow_symbol_change={false}
								symbol={symbolTradingView}
								interval="D"
								locale={auth.lang === "vi" ? "vi_VN" : "en_US"}
								hide_side_toolbar={true}
								show_popup_button={true}
								autosize={true}
								popup_width={width}
								popup_height={height}
								timezone={
									Intl.DateTimeFormat().resolvedOptions()
										.timeZone
								}
							/> */}
							<AdvancedChart
								widgetProps={{
									allow_symbol_change: false,
									symbol: symbolTradingView,
									interval: "D",
									locale:
										auth.lang === "vi" ? "vi_VN" : "en_US",
									hide_side_toolbar: true,
									hide_top_toolbar: true,
									show_popup_button: true,
									popup_width: width,
									popup_height: height,
									timezone:
										Intl.DateTimeFormat().resolvedOptions()
											.timeZone,
									height: 500,
									theme: "light",
									style: "1",
									withdateranges: false,
								}}
							/>
						</div>
						<MobileTradeComponent />
					</div>
				)}
			</>
		</>
	);
}

export default Pro;
