import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./config";

export const authService = createApi({
	reducerPath: "authService",
	baseQuery: baseQuery,

	endpoints: (builder) => ({
		signIn: builder.mutation({
			query: ({ username, password }) => ({
				url: `/auth/signin`,
				method: "POST",
				body: { username, password },
			}),
		}),

		signUp: builder.mutation({
			query: ({
				email,
				password,
				countryCode,
				phone,
				refCode,
				promotionCode,
			}) => ({
				url: `/auth/signup`,
				method: "POST",
				body: {
					email,
					password,
					countryCode,
					phone,
					refCode,
					promotionCode,
				},
			}),
		}),

		resetPassword: builder.mutation({
			query: ({ email }) => ({
				url: `/auth/email/reset-password`,
				method: "POST",
				body: { email },
			}),
		}),

		changePassword: builder.mutation({
			query: ({ password, token }) => ({
				url: `/auth/email/change-password`,
				method: "POST",
				body: { password, token },
			}),
		}),

		getWSToken: builder.query({
			query: () => `socket`,
		}),

		getUserInfo: builder.query({
			query: () => `auth`,
		}),
		enable2FA: builder.mutation({
			query: ({ secret }) => ({
				url: `2fa/enable`,
				method: "POST",
				body: { secret },
			}),
		}),
		generateQR: builder.query({
			query: () => `2fa/generate`,
		}),

		getCountries: builder.query({
			query: () => "/common/country",
		}),

		requestKYC: builder.mutation({
			query: (data) => ({
				url: `/auth/kyc`,
				method: "POST",
				body: data,
			}),
		}),

		getReferralInfo: builder.query({
			query: () => "/referral/info",
		}),

		getReferralRewards: builder.query({
			query: (skip) => `/referral/rewards?skip=${skip || 0}`,
		}),
		getAPIKeyList: builder.query({
			query: () => "/api",
		}),
		createAPIKey: builder.mutation({
			query: (data) => ({
				url: `/api/create`,
				method: "POST",
				body: data,
			}),
		}),
		changeAPIKeyLimit: builder.mutation({
			query: (data) => ({
				url: `/api/edit`,
				method: "POST",
				body: data,
			}),
		}),

		deactivateAPIKey: builder.mutation({
			query: (data) => ({
				url: `/api/deactive`,
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const {
	useEnable2FAMutation,
	useGenerateQRQuery,
	useGetUserInfoQuery,
	useSignInMutation,
	useGetCountriesQuery,
	useSignUpMutation,
	useGetWSTokenQuery,
	useRequestKYCMutation,
	useResetPasswordMutation,
	useChangePasswordMutation,
	useGetReferralInfoQuery,
	useGetReferralRewardsQuery,
	useGetAPIKeyListQuery,
	useCreateAPIKeyMutation,
	useChangeAPIKeyLimitMutation,
	useDeactivateAPIKeyMutation,
} = authService;
