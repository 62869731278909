import { createSlice } from "@reduxjs/toolkit";

export const SpotTrade = createSlice({
	name: "spot",
	initialState: {
		isPro: localStorage.getItem("isPro") === "true",
		buy: [],
		sell: [],
		balance: {
			left: 0,
			right: 0,
		},

		priceChange24h: {
			currentPrice: 0,
			weightedAvgPrice: 0,
			highPrice: 0,
			lowPrice: 0,
			priceChangePercent: 0,
			quoteVolume: 0,
			volume: 0,
			tickSize: 1,
			priceInUSD: 0,
		},

		orderDetail: {
			quantity: 0,
			price: 0,
			range: 0,
			total: 0,
			tradeType: "MARKET",
			side: "BUY",
		},
	},
	reducers: {
		updateBuyOrder: (state, action) => {
			state.buy = action.payload;
		},
		updateSellOrder: (state, action) => {
			state.sell = action.payload;
		},
		updateSpotBalance: (state, action) => {
			state.balance = { ...action.payload };
		},

		updatePriceChange24h: (state, action) => {
			state.priceChange24h = {
				...state.priceChange24h,
				...action.payload,
			};
		},

		updateOrderDetail: (state, action) => {
			state.orderDetail = { ...state.orderDetail, ...action.payload };
		},

		changeProMode: (state, action) => {
			state.isPro = action.payload;
		},

		changeTradeType: (state, action) => {
			state.orderDetail.tradeType = action.payload;
		},
		changeTradeSide: (state, action) => {
			state.orderDetail.side = action.payload;
		},
	},
});

export const {
	updateBuyOrder,
	updateSellOrder,
	updateSpotBalance,
	updatePriceChange24h,
	updateBuyOrderDetail,
	updateSellOrderDetail,
	updateOrderDetail,
	changeProMode,
	changeTradeType,
	changeTradeSide,
} = SpotTrade.actions;

export default SpotTrade.reducer;
export const getBuyOrder = (state) => state.spot.buy;
export const getSellOrder = (state) => state.spot.sell;
