import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import RewardModal from "../../Component/LuckyWheel/RewardModal";

const LuckyWheel = () => {
	const [rotation, setRotation] = useState(0);
	const [deg, setDeg] = useState(0);
	const [power, setPower] = useState(0);
	const [isSpinning, setIsSpinning] = useState(false);
	const [isOpenRewardModal, setIsOpenRewardModal] = useState(false);
	const [reward, setReward] = useState();

	const intervalRef = useRef(null);

	const wheel = useRef();

	const powerUp = () => {
		setPower(0);
		if (intervalRef.current) return;
		intervalRef.current = setInterval(() => {
			setPower((prevPower) => {
				if (prevPower + 1 <= 200) return prevPower + 1;
				return 10;
			});
		}, 10);
	};

	const resetPower = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	const spin = () => {
		let currPower = power <= 100 ? power : power - 100;

		let result = Math.floor(Math.random() * 12);
		setReward(result);

		let maxDeg = 15 + 360 - result * 30;
		let minDeg = 15 + 360 - (result + 1) * 30 + 1;

		let resultDeg =
			Math.floor(Math.random() * (maxDeg - minDeg + 1)) + minDeg;

		let time = 5 + Math.floor(power / 5);

		wheel.current.style.transition = `all ${time}s cubic-bezier(0.16, 1, 0.3, 1)`;

		let changeRotation =
			360 * Math.floor(currPower ** 1.25 / 10 ** 1.25) + 360;

		wheel.current.style.transform = `rotate(${
			rotation + resultDeg - deg + changeRotation
		}deg)`;

		setIsSpinning(true);
		setRotation(rotation + resultDeg - deg + changeRotation);
		setDeg(resultDeg);
	};

	useEffect(() => {
		return () => resetPower();
	}, []);

	useEffect(() => {
		if (isSpinning) {
			const resetTimer = setTimeout(() => {
				setIsSpinning(false);
				setIsOpenRewardModal(true);
			}, (5 + Math.floor(power / 5)) * 1000);

			resetPower();
			return () => clearTimeout(resetTimer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSpinning, power]);

	return (
		<>
			<div className={styles.luckyWheelContainer}>
				<div>
					<div className={styles.luckyWheel} ref={wheel}>
						{[...Array(12).keys()].map((i) => (
							<div className={styles.prizeLabel} key={i}>
								<div>{i}</div>
							</div>
						))}
					</div>
					<button
						className={styles.spinButton}
						onMouseDown={powerUp}
						onMouseUp={spin}
						disabled={isSpinning}
					>
						<div>SPIN</div>
					</button>
					<div className={styles.arrow}></div>
					<div className={styles.powerBar}>
						<div
							style={{
								width: `${
									power <= 100 ? power : 100 - (power - 100)
								}%`,
							}}
						></div>
					</div>
				</div>
			</div>

			{isOpenRewardModal && (
				<RewardModal
					openModal={isOpenRewardModal}
					handleClose={() => setIsOpenRewardModal(false)}
					reward={reward}
				/>
			)}
		</>
	);
};

export default LuckyWheel;
