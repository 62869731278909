import React from "react";
import QRcode from '../../assets/images/qr-code.png';

function AuthRight() {
    return (
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center">
            <div className="qr-block text-center">
                <img src={QRcode} alt="#" className="img-fluid my-4" />
                <h4>Đăng nhập với QR code</h4>
                <p>Quét mã code trên ứng dụng<span className="text-primary"> Frypto</span><br /> để đăng nhập ngay</p>
            </div>
        </div>
    )
}

export default AuthRight;