import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Tabs, Tab, Form } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import {
	useGetTokenListQuery,
	useGetDepositWalletQuery,
	useGetWalletTransactionsQuery,
} from "../../services/wallet";
import QRCode from "qrcode.react";
import LoadingDot from "../Animation/LoadingDot";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyComponent from "../Common/CopyComponent";

const DepositCrypto = () => {
	const [t] = useTranslation("wallet");
	const { data: tokenList } = useGetTokenListQuery();

	const [activeTab, setActiveTab] = useState("CRYPTO");
	const [selectedToken, setSelectedToken] = useState();
	const [networkList, setNetworkList] = useState([]);
	const [selectedNetwork, setSelectedNetwork] = useState();
	const { data: depositAddress } = useGetDepositWalletQuery(selectedNetwork);

	useEffect(() => {
		setNetworkList(
			tokenList?.filter((token) => token.id === selectedToken)[0]
				?.networks
		);
	}, [selectedToken]);

	useEffect(() => {
		if (networkList?.length > 0) setSelectedNetwork(networkList[0].id);
	}, [networkList]);
	return (
		<div className={styles.depositContainer}>
			<Tabs
				activeKey={activeTab}
				onSelect={(key) => setActiveTab(key)}
				className={styles.walletTabs}
			>
				<Tab eventKey={"CRYPTO"} title="Crypto" />
				<Tab eventKey={"FIAT"} title="Fiat(Coming soon)" disabled />
			</Tabs>

			<div className="contentContainerMedium">
				<div className={styles.walletFormContainer}>
					<div className={styles.depositDes}>{t("depositDes")}</div>
					<div className={styles.depositForm}>
						<div>
							<span>{t("chooseYourCrypto")}</span>
							<Form.Select
								className={styles.customSelect}
								disabled={!tokenList}
								onChange={(e) =>
									setSelectedToken(e.target.value)
								}
								value={selectedToken}
							>
								<option value={null}></option>
								{tokenList?.map((token) => (
									<option
										key={token.id}
										value={token.id}
									>{`${token.id} - ${token.name}`}</option>
								))}
							</Form.Select>
						</div>
						{selectedToken && (
							<>
								<div>
									<span>{t("chooseNetwork")}</span>
									<Form.Select
										className={styles.customSelect}
										disabled={!selectedToken}
										onChange={(e) =>
											setSelectedNetwork(e.target.value)
										}
										value={selectedNetwork}
									>
										{networkList?.map((network) => (
											<option
												key={network?.id}
												value={network?.network}
											>
												{network?.metadata?.name}
											</option>
										))}
									</Form.Select>
								</div>
								{depositAddress && (
									<div>
										<span>
											{t("genAddress", {
												crypto: selectedToken,
											})}
										</span>
										<div
											className={
												styles.depositAddressContainer
											}
										>
											{depositAddress?.address && (
												<QRCode
													size={160}
													value={
														depositAddress?.address
													}
												/>
											)}
											<div>
												<CopyComponent
													text={
														depositAddress?.address
													}
												/>

												<div>
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M8.00016 1.33301C4.3135 1.33301 1.3335 4.31301 1.3335 7.99967C1.3335 11.6863 4.3135 14.6663 8.00016 14.6663C11.6868 14.6663 14.6668 11.6863 14.6668 7.99967C14.6668 4.31301 11.6868 1.33301 8.00016 1.33301Z"
															fill="#FDFCFF"
															stroke="#6F6F6F"
														/>
														<circle
															cx="8.00016"
															cy="4.66667"
															r="0.666667"
															fill="#6F6F6F"
														/>
														<rect
															x="7.3335"
															y="6.66699"
															width="1.33333"
															height="5.33333"
															rx="0.666667"
															fill="#6F6F6F"
														/>
													</svg>

													<span>
														{t("depositCaution")}
													</span>
												</div>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepositCrypto;
